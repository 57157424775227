/* eslint-disable import/prefer-default-export */

import { ValueObject } from "src/types/common";
import { EntryFormInputType } from "src/types/entryForm";
import { GLOBAL_PARAMS, UTM_PARAMS } from "src/constants/params";
import {
  LOGICAL_GROUP_CHANNEL_CODE,
  RETAIL_LOGICAL_GROUP_CHANNEL_CODE,
} from "src/constants/logicalGroupChannelCode";
import { isEmpty } from "lodash";
import { LOCALE } from "src/constants/locale";
import { CHANNEL_PATH } from "src/constants/channel";

type GlobalParams = {
  sRefCode: string;
};

type PlanDirectLinkParams = {
  channelPath: string;
  categoryCode: string;
  planCode: string;
  originUrl: string;
};

export const filterParams = (
  params: Partial<GlobalParams & EntryFormInputType>,
  includeList = GLOBAL_PARAMS
): ValueObject<any> => {
  return Object.keys(params || {})
    .filter((key) => includeList.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: params[key],
      }),
      {}
    );
};

export const replaceWordInUrlPath = (
  url: string,
  word: string,
  replacement?: string
) =>
  url.replace(
    new RegExp(`/${word}/`, "g"),
    replacement ? `/${replacement}/` : "/"
  );

export const formatPathByLogicalGroupChannel = (
  path: string,
  logicalGroupChannel = LOGICAL_GROUP_CHANNEL_CODE
) => {
  const result =
    logicalGroupChannel !== RETAIL_LOGICAL_GROUP_CHANNEL_CODE
      ? path
      : // this regex will remove first path eg. /retail/health => /health
        path.replace(/^\/?[^/]+/, "") || "/";

  return result;
};

export const embedLocaleLanguageAndEnvToExternalLink = (
  inputUrl: string,
  language: string = "id",
  env: string = "production"
): string => {
  const url = new URL(inputUrl);

  if (!env || env === "production") {
    return `${url.origin}/${language}${url.pathname}${url.search}${url.hash}`;
  }

  const splitHost = url.host.split(".");

  splitHost.splice(1, 0, env);

  const newOriginWithEnv = splitHost.join(".");

  const newUrl = `${url.protocol}//${newOriginWithEnv}/${language}${url.pathname}${url.search}${url.hash}`;

  return newUrl;
};

export const genPlanDirectLinkShareUrl = (
  { channelPath, categoryCode, planCode, originUrl }: PlanDirectLinkParams,
  lang: string
): string => {
  const utmQueryString = `${UTM_PARAMS.UTM_SOURCE}=sharebutton&${UTM_PARAMS.UTM_MEDIUM}=healthplanpage&${UTM_PARAMS.UTM_CAMPAIGN}=${planCode}`;
  const planDirectLinkPath = formatPathByLogicalGroupChannel(
    `/${channelPath}/content-direct/${categoryCode}/plan/${planCode}?${utmQueryString}`
  );

  const planDirectLinkUrl = `${originUrl}/${lang}${planDirectLinkPath}`;

  return planDirectLinkUrl;
};

// only support simple flat query object
export const getUrlWithQuery = (
  path: string,
  query: {
    [key: string]: string | number;
  }
): string => {
  return `${path}${
    isEmpty(query)
      ? ""
      : `?${Object.entries(query)
          .map(([key, val]) => `${key}=${val}`)
          .join("&")}`
  }`;
};

export const getSundayInsuranceURL = (
  host: string,
  language: string,
  slug = ""
) => {
  return `${host}/${language}/${slug}`;
};

export const getJollyURL = (host: string, language: string, slug = "") => {
  const lang = language === LOCALE.ID ? LOCALE.ID : `${LOCALE.ID}-${LOCALE.EN}`;
  return `${host}/${lang}/${slug}`;
};

export const getSundayRetailURL = (
  env: string,
  language: string,
  channelPath: string
) => {
  if (channelPath === CHANNEL_PATH.SDB) {
    if (!env || env === "production") {
      return `https://retail.easysunday.co.id/${language}/${channelPath}`;
    }

    return `https://retail.${env}.easysunday.co.id/${language}/${channelPath}`;
  }

  if (!env || env === "production") {
    return `https://retail.sundayinsurance.co.id/${language}/${channelPath}`;
  }

  return `https://retail.${env}.sundayinsurance.co.id/${language}/${channelPath}`;
};
