import { createGlobalStyle } from "styled-components";

const GlobalThemeStyles = createGlobalStyle`
  :root ${(props) => props.theme.themeConfig}

  ::selection {
    background: var(--ui-form-selection-background-color,#1890ff);
    color: var(--ui-form-selection-text-color,#fff);
  }
`;

export default GlobalThemeStyles;
