import { axiosErrorUnwrap } from "src/utils/axiosError";
import { ProductCategoryType } from "../types/productCategory";
import { clientHttpInstance, serverHttpInstance } from "./http-client";

export const getProductCategoryActiveList = async (
  channelCode: string
): Promise<ProductCategoryType[]> => {
  const res = await clientHttpInstance.get<ProductCategoryType[]>(
    `/channels/${channelCode}/product-categories/active-list`
  );
  return res.data;
};

export const getProductCategoryActiveListViaServerSide = async (
  channelCode: string
): Promise<ProductCategoryType[]> => {
  const res = await serverHttpInstance.get<ProductCategoryType[]>(
    `/channels/${channelCode}/product-categories/active-list`
  );
  return res.data;
};

export const getProductCategoryByCode = async (
  code: string,
  channelCode: string
): Promise<ProductCategoryType> => {
  try {
    const res = await serverHttpInstance.get<ProductCategoryType>(
      `/channels/${channelCode}/product-categories/${code}`
    );
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};
