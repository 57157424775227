/* eslint-disable import/prefer-default-export */

export const UTM_PARAMS = {
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_TERM: "utm_term",
  UTM_CONTENT: "utm_content",
};

export const UTM_PARAMS_LIST = [
  UTM_PARAMS.UTM_SOURCE,
  UTM_PARAMS.UTM_MEDIUM,
  UTM_PARAMS.UTM_CAMPAIGN,
  UTM_PARAMS.UTM_TERM,
  UTM_PARAMS.UTM_CONTENT,
];

export const GLOBAL_PARAMS = [...UTM_PARAMS_LIST, "sRefCode", "apTraceId"];

export const ENTRY_FORM_PARAMS = [
  "insuredDateOfBirth",
  "effectiveDate",
  "insuredGender",
];
