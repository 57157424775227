const path = require("path");

const defaultLocale = process.env.DEFAULT_LOCALE || "en";
const supportedLocaleList = JSON.parse(
  process.env.SUPPORTED_LOCALE_LIST || '["en"]'
);

module.exports = {
  i18n: {
    defaultLocale,
    locales: supportedLocaleList,
    localeDetection: false,
  },
  localePath: path.join(__dirname, "public/static/locales"),
  react: { useSuspense: false },
};
