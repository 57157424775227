import axios from "axios";

const serverHttpInstance = axios.create({
  baseURL: process.env.NEXT_PRIVATE_API_BASE_URL,
});

const clientHttpInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

// TODO, will fix this later
// clientHttpInstance.interceptors.request.use((requestConfig: AxiosRequestConfig<any>) => {
//   requestConfig.headers['x-request-id'] = generateRequestId();
// });

// const generateRequestId = () => {
//   const reqId = uuidv4();
//   sessionStorage.setItem('reqId', reqId);
//   return reqId;
// }

export { serverHttpInstance, clientHttpInstance };
