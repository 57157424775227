import { ChannelConfigType } from "src/types/channelConfig";
import { axiosErrorUnwrap } from "src/utils/axiosError";
import { serverHttpInstance } from "./http-client";

const getChannelConfigByPathName = async (
  pathName: string
): Promise<ChannelConfigType> => {
  try {
    const res = await serverHttpInstance.get<ChannelConfigType>(
      `/channel/${pathName}`
    );

    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};
export default getChannelConfigByPathName;
