export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || "";

export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH || "";

export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW || "";

export const GTM_EVENT = {
  PURCHASE: "purchase",
  SUBMIT_APP_FORM: "click_submit_app_form",
  CONTINUE_ENTRY_FORM: "click_continue_basic_info",
};
