/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, createContext } from "react";

import { getProductCategoryActiveList } from "../../api/productCategory";

import { logError } from "../../utils/logger";

import type { ProductCategory } from "../../types/productCategory";

interface ProductCategoryContextType {
  isLoading: boolean;
  productCategories: ProductCategory[];
}

interface ProductCategoryProviderProps {
  channelCode: string;
  children: React.ReactNode;
}

const DEFAULT_CONTEXT_VALUE: ProductCategoryContextType = {
  isLoading: false,
  productCategories: [],
};

interface ProductCategoryProviderState extends ProductCategoryContextType {}

export const ProductCategoryContext = createContext<ProductCategoryContextType>(
  DEFAULT_CONTEXT_VALUE
);

export const ProductCategoryProvider: React.FC<ProductCategoryProviderProps> =
  ({ children, channelCode }) => {
    const [state, setState] = useState<ProductCategoryProviderState>(
      DEFAULT_CONTEXT_VALUE
    );

    useEffect(() => {
      const getProductCategories = async () => {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        try {
          const productCat = await getProductCategoryActiveList(channelCode);
          const mappedProductCat = productCat
            .map((product) => ({
              ...product,
              icon: product.icon.url,
            }))
            .sort((a, b) => a.seq - b.seq);

          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            productCategories: mappedProductCat,
          }));
        } catch (error) {
          logError("Error fetch product category", { error });
        }
      };
      getProductCategories();
    }, []);

    return (
      <ProductCategoryContext.Provider value={state}>
        {children}
      </ProductCategoryContext.Provider>
    );
  };
