import "../styles/globals.css";
import Head from "next/head";
import type { AppContext, AppProps } from "next/app";
import { useRouter } from "next/router";
import { i18nCreator } from "sunday-component";
import { appWithTranslation, SSRConfig, useTranslation } from "next-i18next";
import { ThemeProvider, DefaultTheme } from "styled-components";
import React from "react";
import "antd/dist/antd.min.css";
import "sunday-component/lib/styles.css";
import Script from "next/script";
import App from "next/app";
import GlobalThemeStyles from "src/styles/globalThemeStyle";
import getChannelConfigByPathName from "src/api/channel";
import { ChannelConfigType } from "src/types/channelConfig";
import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from "src/constants/gtm";
import { logError } from "src/utils/logger";
import { getPath } from "../utils/router";
import Layout from "../components/Layout";
import { ProductCategoryProvider } from "../providers";
import "moment/locale/id";

function MyApp({
  Component,
  pageProps,
}: AppProps<
  { theme: DefaultTheme; channelConfig: ChannelConfigType } & SSRConfig
>) {
  const router = useRouter();
  const [rootPath, setRootPath] = React.useState("");
  const supportedLangCodes = pageProps?.channelConfig?.content?.supportedLangs
    .map((lang: { code: string }) => lang.code)
    .join("|");
  const currentLanguage = getPath(router.locale).lang;
  // if you fix re-render the whole app when change-language then use instance form i18nCreator instend
  i18nCreator(currentLanguage);

  const { t } = useTranslation("common");

  React.useEffect(() => {
    setRootPath(`${window.location.protocol}//${window.location.host}`);
  }, []);

  const generateUrl = (locale) => {
    return `${rootPath}/${locale}${router.asPath
      .replace(new RegExp(`^/(${supportedLangCodes})/`, "i"), "")
      .replace(/\?.*$/, "")}`;
  };

  return (
    <>
      <Head>
        <title>
          {pageProps?.channelConfig?.content?.webHeader?.title?.[
            currentLanguage
          ] || t("header.title")}
        </title>
        <link
          rel="icon"
          href={
            pageProps?.channelConfig?.content?.webHeader?.icon ||
            "/static/favicon.png"
          }
        />
        <meta
          name="description"
          content={
            pageProps?.channelConfig?.content?.webHeader?.description?.[
              currentLanguage
            ] || t("header.description")
          }
        />
        <meta
          property="og:image"
          content={
            pageProps?.channelConfig?.content?.webHeader?.image ||
            "/static/web-image.jpeg"
          }
        />
        <link rel="canonical" href={generateUrl(currentLanguage)} />
        {pageProps?.channelConfig?.content?.supportedLangs.map(
          (locale: { code: string }) => (
            <link
              rel="alternate"
              href={generateUrl(locale?.code)}
              hrefLang={locale?.code}
            />
          )
        )}
        <link rel="alternate" href={generateUrl("en")} hrefLang="x-default" />
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i,x,y){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth='+x+'&gtm_preview='+y;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}','${GTM_AUTH}','${GTM_PREVIEW}');
      `}
      </Script>
      <ThemeProvider theme={pageProps.theme}>
        <GlobalThemeStyles />
        <ProductCategoryProvider channelCode={pageProps.channelConfig.code}>
          <Layout content={pageProps.channelConfig.content}>
            <Component {...pageProps} />
          </Layout>
        </ProductCategoryProvider>
      </ThemeProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`

  const appProps = await App.getInitialProps(appContext);
  const theme: DefaultTheme = {
    breakpoints: {
      xs: "480px",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      xxl: "1600px",
    },
    themeConfig: null,
  };
  try {
    let isDuplicateQuery = false;
    const queryObj = {};

    const query = new URLSearchParams(
      appContext.router.asPath.split("?")?.[1] || ""
    );

    Array.from(query.keys()).forEach((key) => {
      const searchParamsValue = query.getAll(key);
      if (searchParamsValue.length > 1) {
        isDuplicateQuery = true;
      }
      [queryObj[key]] = searchParamsValue;
    });

    // DEV-NOTE: should move to nextjs middleware
    // (wait @sentry/nextjs (v7.41.0) stable for nextjs middleware)
    // remove duplicate query string (not work in getServerSideProps)
    if (isDuplicateQuery) {
      // send error to sentry.
      logError("Error duplicate query string", {
        query: appContext.router.query,
        path: appContext.router.asPath,
      });
      const querystring = new URLSearchParams(queryObj).toString();
      appContext.ctx.res.writeHead(307, {
        Location: `${appContext.router.asPath.split("?")?.[0]}?${querystring}`,
      });
      appContext.ctx.res.end();
    }

    const { channelPath } = appContext.router.query;

    const path = appContext.router.asPath.split("/");
    if (channelPath) {
      const channelConfig = await getChannelConfigByPathName(
        (channelPath as string) ?? "retail"
      );

      theme.themeConfig = channelConfig.theme;

      appProps.pageProps = {
        theme,
        channelConfig,
      };

      return appProps;
    }

    if (path.length > 1) {
      const channelConfig = await getChannelConfigByPathName(path[1] as string);

      theme.themeConfig = channelConfig.theme;

      appProps.pageProps = {
        theme,
        channelConfig,
      };
      return appProps;
    }
    appProps.pageProps = {
      theme,
      channelConfig: { content: null },
    };
    return appProps;
  } catch (error) {
    appProps.pageProps = {
      theme,
      channelConfig: { content: null },
    };
    return appProps;
  }
};

export default appWithTranslation(MyApp);
