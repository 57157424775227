import { ReactElement, ReactNode, useMemo } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { useRouter } from "next/router";
import {
  Header as SundayHeader,
  IndoFooter as SundayIndoFooter,
  ChatBox,
} from "sunday-component";
import {
  LOGICAL_GROUP_CHANNEL_CODE,
  RETAIL_LOGICAL_GROUP_CHANNEL_CODE,
} from "src/constants/logicalGroupChannelCode";
import { useTranslation } from "react-i18next";
import getConfig from "next/config";
import { CHANNEL_PATH, INDO_COMPANY_TYPE } from "src/constants/channel";
import type { ISundayContact } from "sunday-component/lib/src/components/Footer/types/IFooterMenu";
import { Language } from "../Common/Header/IHeader";
import {
  getSundayRetailURL,
  getJollyURL,
  getSundayInsuranceURL,
} from "../../utils/url";

type SundayLayoutProps = {
  children: ReactNode;
  content: any;
};

const { Content } = Layout;

const Container = styled(Layout)`
  min-height: 100vh;
  background: var(--ui-form-background_page-background-color);
`;

const CustomContent = styled(Content)`
  min-height: 66.67vh;
`;

const SundayLayout = ({
  children,
  content,
}: SundayLayoutProps): ReactElement => {
  const router = useRouter();
  const { t } = useTranslation(["common"]);
  const {
    publicRuntimeConfig: {
      NODE_ENV,
      SUNDAY_INSURANCE_URL,
      SUNDAY_NEWS_URL,
      SUNDAY_CAREER_URL,
      JOLLY_URL,
    },
  } = getConfig();
  const { locale, query } = router;

  const contacts: ISundayContact[] = useMemo(() => {
    if (content && content.contacts) {
      return [
        {
          type: "tel",
          label: content.contacts.cs.tel,
          value: content.contacts.cs.tel.split(" ").join(""),
        },
        {
          type: "mail",
          label: content.contacts.email.email,
          value: content.contacts.email.email,
        },
        {
          type: "wa",
          label: content.contacts.wa.waId,
          value: content.contacts.wa.waIdLink,
        },
      ];
    }

    return [];
  }, [content]);

  const company = useMemo(() => {
    if (query.channelPath === CHANNEL_PATH.SDI) {
      return INDO_COMPANY_TYPE.ID_SDI;
    }
    return INDO_COMPANY_TYPE.ID_SDB;
  }, [query]);

  const isUseSundayLayout =
    LOGICAL_GROUP_CHANNEL_CODE === RETAIL_LOGICAL_GROUP_CHANNEL_CODE ||
    !content;
  const onChangeLanguage = (language: any) => {
    if (language !== router.locale) {
      router.replace(router.asPath, router.asPath, { locale: language });
    }
  };
  const availableLanguages = content?.supportedLangs.map((lang) => lang.code);

  const menusHeader = [
    {
      label: t("header-sdi.buy-online"),
      href: getSundayRetailURL(NODE_ENV, locale, query.channelPath as string),
    },
    {
      label: t("header-sdi.products"),
      href: getSundayInsuranceURL(SUNDAY_INSURANCE_URL, locale, "product"),
      showSubMenu: true,
      subMenus: [
        {
          label: t("header-sdi.product-submenu-1"),
          href: getSundayInsuranceURL(
            SUNDAY_INSURANCE_URL,
            locale,
            "product/#individual"
          ),
          items: [
            {
              label: t("header-sdi.product-submenu-individual-1"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#personal-accident"
              ),
            },
            {
              label: t("header-sdi.product-submenu-individual-2"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#motor-vehicle"
              ),
            },
            {
              label: t("header-sdi.product-submenu-individual-3"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#property"
              ),
            },
            {
              label: t("header-sdi.product-submenu-individual-4"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#employee-health"
              ),
            },
            {
              label: t("header-sdi.product-submenu-individual-5"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#international-health"
              ),
            },
            {
              label: t("header-sdi.product-submenu-individual-6"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#miscellaneous"
              ),
            },
          ],
        },
        {
          label: t("header-sdi.product-submenu-2"),
          href: getSundayInsuranceURL(
            SUNDAY_INSURANCE_URL,
            locale,
            "product/#commercial"
          ),
          items: [
            {
              label: t("header-sdi.product-submenu-commercial-1"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#cargo"
              ),
            },
            {
              label: t("header-sdi.product-submenu-commercial-2"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#com-motor-vehicle"
              ),
            },
            {
              label: t("header-sdi.product-submenu-commercial-3"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#heavy-equipment"
              ),
            },
            {
              label: t("header-sdi.product-submenu-commercial-4"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#engineering"
              ),
            },
            {
              label: t("header-sdi.product-submenu-commercial-5"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#com-property"
              ),
            },
            {
              label: t("header-sdi.product-submenu-commercial-6"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "product/#com-miscellaneous"
              ),
            },
          ],
        },
      ],
    },
    {
      label: t("header-sdi.about-us"),
      href: getSundayInsuranceURL(SUNDAY_INSURANCE_URL, locale, "about/people"),
      showSubMenu: true,
      subMenus: [
        {
          items: [
            {
              label: t("header-sdi.about-submenu-1"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "about/introduction"
              ),
            },
            {
              label: t("header-sdi.about-submenu-2"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "about/people"
              ),
            },
            {
              label: t("header-sdi.about-submenu-3"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "about/reports"
              ),
            },
            {
              label: t("header-sdi.about-submenu-4"),
              href: SUNDAY_NEWS_URL,
            },
            {
              label: t("header-sdi.about-submenu-5"),
              href: SUNDAY_CAREER_URL,
            },
          ],
        },
      ],
    },
    {
      label: t("header-sdi.services"),
      href: getSundayInsuranceURL(
        SUNDAY_INSURANCE_URL,
        locale,
        "services/panel"
      ),
      showSubMenu: true,
      subMenus: [
        {
          items: [
            {
              label: t("header-sdi.services-submenu-1"),
              href: getJollyURL(JOLLY_URL, locale),
            },
            {
              label: t("header-sdi.services-submenu-2"),
              href: getSundayInsuranceURL(
                SUNDAY_INSURANCE_URL,
                locale,
                "services/panel"
              ),
            },
          ],
        },
      ],
    },
    {
      label: t("header-sdi.faq"),
      href: getSundayInsuranceURL(SUNDAY_INSURANCE_URL, locale, "faq"),
    },
    {
      label: t("header-sdi.contact-us"),
      href: getSundayInsuranceURL(SUNDAY_INSURANCE_URL, locale, "contact"),
    },
  ];

  return (
    <Container>
      {content?.askUsChatbot?.enabled ? <ChatBox branch="thai" /> : <></>}
      {isUseSundayLayout && (
        <>
          {query.channelPath === CHANNEL_PATH.SDB ? (
            <SundayHeader
              showSwitchLanguage
              type="indo"
              onChangeLanguage={onChangeLanguage}
              language={locale as Language}
              theme="white" // 'orange' | 'white' | 'yellow' | 'grey' | 'coolGreyAndWhite'
              availableLanguages={availableLanguages}
              envType={NODE_ENV}
            />
          ) : (
            <SundayHeader
              menus={menusHeader}
              type="sunday"
              onChangeLanguage={onChangeLanguage}
              language={locale as Language}
              showSwitchLanguage
              availableLanguages={availableLanguages}
              sundayLogoHref={getSundayRetailURL(
                NODE_ENV,
                locale,
                query.channelPath as string
              )}
              theme="white"
              contacts={contacts}
            />
          )}

          <CustomContent>{children}</CustomContent>

          <SundayIndoFooter company={company} envType={NODE_ENV} />
        </>
      )}
    </Container>
  );
};

export default SundayLayout;
