import { IndoCompany } from "sunday-component/lib/src/components/Header/IHeader";

const CHANNEL_SIZE = {
  M: "M",
  L: "L",
};

export const CHANNEL_PATH = {
  SDI: "sdi",
  SDB: "sdb",
};

export const INDO_COMPANY_TYPE: { [key: string]: IndoCompany } = {
  ID_SDI: "ID-SDI",
  ID_SDB: "ID-SDB",
};

export default CHANNEL_SIZE;
