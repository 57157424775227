import * as Sentry from "@sentry/nextjs";
import pkg from "../../package.json";

const metaData = {
  version: pkg.version,
};

// flush issue to sentry as error issues.
const sentryFlushError = (message: any, data: any = null, error: Error) => {
  Sentry.configureScope((scope) => {
    scope.setLevel(Sentry.Severity.Error);
    scope.setContext("metadata", metaData);
    scope.setContext("data", data);
    scope.setContext("message", { message });
    Sentry.captureException(error);
  });
};

// flush issue to sentry as warning issues.
const sentryFlushWarn = (message: any, data: any = null, error: Error) => {
  Sentry.configureScope((scope) => {
    scope.setLevel(Sentry.Severity.Warning);
    scope.setContext("metadata", metaData);
    scope.setContext("data", data);
    scope.setContext("message", { message });
    Sentry.captureException(error);
  });
};

// add message to sentry breadcrumbs
const sentryBreadcrumb = (
  type: string,
  category: string,
  level: Sentry.Severity,
  message: string,
  data: any
) => {
  Sentry.configureScope((scope) => {
    scope.addBreadcrumb({
      type,
      level,
      category,
      message,
      data,
    });
  });
};

const logInfo = (message: string, data: any = null) => {
  sentryBreadcrumb("info", "logger", Sentry.Severity.Info, message, {
    metaData,
    data,
  });
};

const logDebug = (message: string, data: any = null) => {
  sentryBreadcrumb("debug", "logger", Sentry.Severity.Debug, message, {
    metaData,
    data,
  });
};

const logError = (message: any, data: any = null, error: Error = null) => {
  const errorObj = error instanceof Error ? error : message;
  sentryFlushError(message, data, errorObj);
};

const logWarn = (message: any, data: any = null, error: Error = null) => {
  const errorObj = error instanceof Error ? error : message;
  sentryFlushWarn(message, data, errorObj);
};

export { logInfo, logDebug, logError, logWarn };
