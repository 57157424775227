/* eslint-disable import/prefer-default-export */

import { AxiosError } from "axios";

export type AxiosErrorUnwrapType = {
  type: string;
  code?: string;
  nested?: Error;
  message?: string;
};
/**
 * unwrap axios error to get error data.
 * @param error input number;
 * @returns axios error response data.
 */
export const axiosErrorUnwrap = (
  error: AxiosError<AxiosErrorUnwrapType>
): AxiosErrorUnwrapType => {
  if (error?.response?.data?.code) {
    return error.response.data;
  }

  throw error;
};
